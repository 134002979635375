import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="leaderboard"
export default class extends Controller {
  updateLeaderboard(event) {
    var updated_city_id = event.target.selectedOptions[0].value;

    if (updated_city_id == "All") {
      window.location.href = "/organisations-and-companies/leaderboard";
    } else {
      window.location.href =
        "/organisations-and-companies/leaderboard?updated_city_id=" +
        updated_city_id;
    }
  }
}
